import styles from '../../Styles/col.module.css'

const MobileButton = ({setIsOpen}) => {
    return (
        <div className={`xs:block md:hidden w-full fixed bottom-0 z-40 opacity-0`} id="asesoria_button">
            <div className={`${styles['col-12']}`}>
                <div className="flex justify-center">
                    <div className="thankyou_process_card_button">
                        <button className="mt-7 font-fredoka inline-block text-blue-new-button-text relative rounded-full text-xl h-16 cursor-pointer px-2 hover:bg-hover-orange bg-button-orange py-3 tracking-wider w-10/12" onClick={setIsOpen}>
                            Haz clic para empezar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileButton