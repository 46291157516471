import cookie from "js-cookie"; 
//Function error de correo
export const onLeadError = (error, data) => {
    if (error.name == "email_not_available") {
        const { email } = data;
        error.message = `El email ${email} ya ha sido registrado, revisa tu bandeja de entrada para continuar.`;
    }
    window.dataLayer?.push({
        event: 'exception',
        description: error.name,
        fatal: false
    });
    return {message: error.message, loadingSignup: false}
}

//Fuction ADS
export const stringParamsToObject = path => {
    if (!path) return {};
    return JSON.parse('{"' + decodeURI(path.substring(path.indexOf("?") + 1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export const getAdsParameters = (paramsCookieName, router) => {
    const paramsAsString = cookie.get(paramsCookieName);
    const adsParameters = Object.keys(router.query).length > 0 ? router.query : stringParamsToObject(paramsAsString);
    return adsParameters
}
