import React, { useContext } from 'react';
import Image from 'next/image';
import { PromotionContext } from '../../pages/_app';
import dynamic from 'next/dynamic';
const Cards = dynamic(() => import('../Home/Cards'), {
    ssr: false,
});

const PricesV2 = () => {
    let { promo } = useContext(PromotionContext);
    
    return (
        <div className={`relative ${promo ? 'pt-16' : 'pt-0'} bg-blue-stress w-full`} id='!servicios-y-precios'>
            <div className={`w-full`} >
                <div id={"section_prices_home" } className="w-full">
                    <span ><span className="spacer"></span></span>
                </div>
                <section id="section_Prices" className="w-full">
                    <div className="xs:hidden md:block">
                        <Image
                            src={`https://cdn.fixat.mx/assets/home/bg/no-webp/bg_004.jpeg`}
                            alt="Fondo de precios"
                            objectFit="cover"
                            className="z-1"
                            layout="fill"
                            quality={100}
                            loading="lazy"
                            objectPosition="center center"
                        />
                    </div>
                    <div className="xs:block md:hidden">
                        <Image
                            src={`https://cdn.fixat.mx/assets/home/bg/no-webp/bg_004_m.jpeg`}
                            alt="Fondo de precios"
                            objectFit="cover"
                            className="z-1"
                            layout="fill"
                            quality={100}
                            loading="lazy"
                            objectPosition="center center"
                        />
                    </div>
                    <div className="pw h-full">
                        <h2 className="text-white font-fredoka text-4.5xl tracking-wide mt-20 text-center flex justify-center">
                            Precios justos para tu tranquilidad fiscal
                        </h2>
                        <div className="flex justify-center w-full pt-1">
                            <div className="flex w-auto items-center rounded-full ">
                                <span className="font-fredoka text-button-orange text-3xl rounded-full cursor-default px-8 tracking-wide text-center">Servicio exclusivo para Personas Físicas.</span>
                            </div>
                        </div>
                        <Cards />
                        <p className="text-white font-quicksand text-2xl text-center block my-16 tracking-wider">
                            Los precios incluyen I.V.A.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PricesV2;