import { createSession } from '../../apiClient/operations/sessionOperations';
import dynamic from 'next/dynamic';
import Input from '../Hooks/Input';
import BadgePromo from '../AsesoriaFiscal/badge-promo';
import { MailIcon } from '@heroicons/react/solid';
import { onLeadError, getAdsParameters } from '../../util/util';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Image from 'next/image';
import { useRouter } from 'next/router';

const FormCard = ({ promo, signupLoading, setSignupLoading }) => {

    const RouteLoader = dynamic(() => import('../Common/RouteLoader'))
    const [submited, setSubmited] = useState(false);
    const [referrer, setReferrer] = useState('');
    const [error, setError] = useState(null);
    const buttonEmailRef = useRef();
    const buttonNameRef = useRef();
    const buttonPhoneRef = useRef();
    const router = useRouter();

    const form = useForm();
    const {
        register,
        handleSubmit,
        reset,
        watch,
        trigger,
        formState: { errors, dirtyFields },
    } = form;

    function isFieldValid(form, field) {
        return form.formState.dirtyFields[field] && !form.formState.errors[field] && submited
    }

    const wName = watch('name')
    useEffect(() => {
        if (dirtyFields.name === true && submited) {
            buttonNameRef.current.click()
        }

    }, [wName, dirtyFields.name, submited]);

    const wPhone = watch('phone');
    useEffect(() => {
        if (dirtyFields.phone === true && submited) {
            buttonPhoneRef.current.click()
        }

    }, [wPhone, dirtyFields.phone, submited]);

    const wEmail = watch('email');
    useEffect(() => {
        if (dirtyFields.email === true && submited) {
            buttonEmailRef.current.click()
        }

    }, [wEmail, dirtyFields.email, submited]);


    const onRouteChangeComplete = loading => {
        setSignupLoading(loading);
        reset();
    }

    useEffect(() => {
        setReferrer(document?.referrer || '');
    }, []);

    const onSubmit = (data, e) => {
        e.preventDefault();
        if (error) { setError(null) }
        setSignupLoading(true);
        const { name, email, phone } = data;
        const campaign_ads = getAdsParameters("fixat_utm_params", router);
        const source = campaign_ads?.utm_source || "asforganic";
        campaign_ads['referrer'] = referrer;
        createSession(name, email, `+52${phone.replace(/\s+/g, '')}`, source, campaign_ads).then(response => {
            localStorage.setItem('lead', JSON.stringify(response));
            console.log(response)
            router.push('/asf/registered');

        }).catch(error => {
            const { message, loadingSignup } = onLeadError(error, data)
            setError(message);
            setSignupLoading(loadingSignup);
        });
    }

    const showErrors = () => {
        setSubmited(true)
        trigger('name')
        trigger('email')
        trigger('phone')
    }

    return (
        <div className="w-full flex-1 flex flex-col py-10 px-6 box-border items-start justify-start gap-[22px] max-w-[538px] text-center text-sm h-fit mmd:items-center mmd:justify-center ssm:h-full font-text-sm-leading-5-font-normal mmd:gap-5 mmd:min-w-full mmd:flex-[unset] mmd:self-stretch ssm:flex-[unset] ssm:self-stretch ssm:gap-4 z-10">
            <div className={`pl-10 ssm:pl-0 ${(promo) ? 'hidden' : 'block'}`} id='form'>
                <BadgePromo text={process.env.BADGE_TEXT_ASF} src={'/asesoria-fiscal'} />
            </div>
            <div className="self-stretch relative items-center flex justify-center">
                <p className='max-w-[350px] text-[28px] leading-[33px] font-fredoka-one text-steelblue-500 mmd:max-w-none'>
                    Regístrate y toma el control de tus impuestos
                </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className=" w-full flex flex-col items-center justify-start gap-[16px] text-left font-quicksand">
                <Input
                    label='Correo electrónico'
                    type='email'
                    autofocus
                    id='email'
                    name='email'
                    placeholder={'Ej. mail@correo.mx'}
                    needed
                    leftIcon={<MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                    pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
                    minLength={6}
                    register={register}

                    errorsText={['Ingresa tu correo electrónico, por favor.', 'El correo electónico no es válido', 'El correo electrónico debe tener mínimo 11 caracteres']}
                    isValid={isFieldValid(form, 'email')}
                    errors={errors}
                />
                <Input
                    label='Nombre completo'
                    type='text'
                    id='name'
                    name='name'
                    placeholder={'Ej. María Félix García Sánchez'}
                    needed
                    pattern={/^[A-Za-zÁÉÍÓÚáéíóúÜü,. -']{1,45}$|^$/}
                    minLength={1}
                    register={register}
                    errorsText={['Ingresa tu nombre completo, por favor.', 'El nombre completo no es válido, no ingreses números', 'El nombre completo debe tener 1 carácter']}
                    isValid={isFieldValid(form, 'name')}
                    errors={errors}
                />
                <Input
                    label='Número de WhatsApp'
                    type='tel'
                    id='phone'
                    name='phone'
                    placeholder={'5559876543'}
                    needed
                    pattern={/^[0-9]+$/}
                    minLength={10}
                    maxLength={10}
                    register={register}
                    errorsText={['Ingresa tu número de teléfono, por favor.', 'El número de teléfono no es válido, ingresa solo números', 'El número de telefono debe tener 10 caracteres']}
                    isValid={isFieldValid(form, 'phone')}
                    errors={errors}

                />
                <button className='hidden' type='button' ref={buttonNameRef} onClick={() => { trigger('name') }}>
                </button>
                <button className='hidden' type='button' ref={buttonPhoneRef} onClick={() => { trigger('phone') }}>
                </button>
                <button className='hidden' type='button' ref={buttonEmailRef} onClick={() => { trigger('email') }}>
                </button>
                <button className="mt-3 cursor-pointer [border:none] py-4 px-[30px] md:hover:bg-hover-orange bg-button-orange self-stretch rounded-81xl flex flex-row items-center justify-center ssm:items-center ssm:justify-center" type='submit' onClick={showErrors}>
                    {signupLoading ? (
                        <RouteLoader setLoading={onRouteChangeComplete} />
                    )
                        : (
                            <b className="relative text-lg ssm:text-base font-fredoka text-blue-new-button-text text-left ssm:text-center">Haz clic aquí para empezar</b>)
                    }
                </button>
                {error &&
                    <div className="flex w-full mt-3">
                        <span className="w-full help-block-error">{error}</span>
                    </div>
                }
            </form>
            <div className="w-full flex flex-row items-center justify-center px-4 gap-[32px] text-left text-base text-negro-textos ssm:w-full">
                <div className="flex-1 relative">{`Con la tecnología SSL tus datos personales están siempre protegidos. `}</div>
                <div className="relative w-[73px] h-14 object-cover">
                    <Image
                        loading="lazy"
                        layout='fill'
                        alt="lets_encrypted"
                        src="https://cdn.fixat.mx/assets/home/img/lets_encrypt.png"
                    />
                </div>

            </div>
        </div>
    );
};

export default FormCard;
